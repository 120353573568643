<template>
  <div class="order-page">

    <h1>Order management</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>User</th>
          <th class="text-center">Amount/Payed</th>
          <th>Gym</th>
          <th>Status</th>
          <th width="1%" class="text-nowrap text-center">Start time</th>
          <th width="1%" class="text-nowrap text-center">End time</th>
          <th width="1%" class="text-nowrap text-center">Date create</th>
        </tr>
        </thead>
        <tbody v-if="orderList?.length > 0">
          <tr v-for="orderData in orderList" :key="orderData.id">
            <td class="text-nowrap">{{ orderData.id }}</td>
            <td>
              <span v-if="orderData.userData" class="small">
                {{ orderData.userData.email }}
              </span>
            </td>
            <td class="text-center">
              {{ this.getPrice(orderData.amount) }}{{ this.getCurrencySymbol() }}
            </td>
            <td>
              <span v-if="orderData.placeData" class="small">
                {{ orderData.placeData.title }}
              </span>
            </td>
            <td>
              <span class="badge bg-secondary">
                {{ orderStatusEnum.getLabels()[orderData.status] }}
              </span>
            </td>
            <td class="text-nowrap text-center small">
              <span v-if="orderData.dateStart">{{ this.getDateTime(orderData.dateStart) }}</span>
            </td>
            <td class="text-nowrap text-center small">
              <span v-if="orderData.dateFinish">{{ this.getDateTime(orderData.dateFinish) }}</span>
            </td>
            <td class="text-nowrap text-center small">
              {{ this.getDateTime(orderData.dateCreate) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="orderPager" :route="'/order/admin'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>
import PagerNav from "@/components/PagerNav";
import {OrderStatusEnum} from "@/modules/order/enums/OrderEnum";

export default {
  components: {PagerNav},

  data: () => ({
    orderList: [],
    orderPager: {},
    orderStatusEnum: OrderStatusEnum
  }),

  async mounted() {

    if (!await this.checkManager())
      return;

    this.setBreadcrumbs();

    await this.doOrderAdminIndex();
  },

  methods: {

    async onPagerUpdate() {

      await this.doOrderAdminIndex();
    },

    async doOrderAdminIndex() {

      let apiHolder = await this.sendApiGet(['api/order/admin/index', {
        page: this.$route.query?.page || 1
      }]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.orderList)
        this.orderList = apiHolder.data.orderList;
      if (apiHolder.data?.orderPager)
        this.orderPager = apiHolder.data.orderPager;
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        'Order management'
      ]);
    }

  }

}
</script>

<style scoped>

</style>