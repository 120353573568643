<template>

  <nav v-if="pager.isShow">

    <ul class="pagination">
      <li class="page-item" :class="{disabled: pager.page == 1}">
        <a class="page-link" href="#" aria-label="Previous" @click.prevent="onPagerClick(pager.page - 1)">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item"
          v-for="(pageData, key) in getPageList()"
          :key="key"
          :class="{active: pageData.page == pager.page}">
        <a class="page-link" href="#" @click.prevent="onPagerClick(pageData.page)">{{ pageData.title }}</a>
      </li>
      <li class="page-item" :class="{disabled: pager.page == pager.pageMax}">
        <a class="page-link" href="#" aria-label="Next" @click.prevent="onPagerClick(pager.page + 1)">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>

</template>

<script>

export default {
  name: 'PagerNav',

  props: {
    pager: Object,
    route: String
  },

  methods: {

    getPageList() {

      let pagerList = [];
      let prevBreak = false;
      let nextBreak = false;

      for (let k = 1; k <= this.pager.pageMax; k++) {

        if (k == 1 || Math.abs(this.pager.page - k) <= 3 || k == this.pager.pageMax) {

          pagerList.push({
            title: k,
            page: k
          });
        }
        else if (k < this.pager.page && !prevBreak) {

          prevBreak = true;

          pagerList.push({
            title: '...',
            page: Math.floor((this.pager.page - 1) / 2)
          });
        }
        else if (k > this.pager.page && !nextBreak) {

          nextBreak = true;

          pagerList.push({
            title: '...',
            page: Math.ceil((this.pager.pageMax - this.pager.page + 2) / 2 + this.pager.page)
          });
        }
      }

      return pagerList;
    },

    async onPagerClick(page) {

      await this.$router.push({path: this.route, query: {page: page}});

      this.$emit("onPagerUpdate");
    }

  }
}
</script>

<style scoped>

</style>
