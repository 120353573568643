
const OrderStatusEnum = {

	new: 'new',
	ready: 'ready',
	open: 'open',
	stop: 'stop',
	close: 'close',
	cancel: 'cancel',
	delete: 'delete',

	getLabels: function() {

		return {
			new: 'created',
			ready: 'ready',
			open: 'active',
			stop: 'waiting',
			close: 'complete',
			cancel: 'cancel',
		}
	}

};

module.exports = {OrderStatusEnum};